.contact-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.contact-hero {
  width: 100%;
  height: 1001px;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #5228F5;
}
.contact-menu {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contact-mobile-navigation {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 150;
  position: fixed;
  transform: translateX(100%);
  transition: transform 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.contact-logo {
  width: 311px;
}
.contact-links {
  gap: var(--dl-space-space-twounits);
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
}
.contact-close-mobile-menu {
  top: var(--dl-space-space-oneandhalfunits);
  right: var(--dl-space-space-oneandhalfunits);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.contact-icon {
  width: 24px;
  height: 24px;
  display: flex;
}
.contact-desktop-navigation {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
}
.contact-centered {
  width: 100%;
  height: 80px;
  display: flex;
  max-width: 1200px;
  box-sizing: initial;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: space-between;
  background-color: #ffffff;
}
.contact-left {
  height: 55px;
  display: flex;
  align-items: center;
  padding-top: 38px;
  flex-direction: row;
  padding-bottom: 38px;
}
.contact-navlink {
  display: contents;
}
.contact-logo1 {
  width: 381px;
  height: 54px;
  margin-left: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.contact-links1 {
  display: flex;
  align-items: center;
  margin-left: 33px;
  flex-direction: row;
}
.contact-navlink1 {
  text-decoration: none;
}
.contact-navlink2 {
  text-decoration: none;
}
.contact-navlink3 {
  text-decoration: none;
}
.contact-right {
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.contact-get-started {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-color: #000000;
}
.contact-get-started:hover {
  background-color: #5228F5;
}
.contact-text03 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 600;
}
.contact-burger-menu {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.contact-mobile-menu-button {
  width: 40px;
  height: 40px;
  display: none;
}
.contact-cards {
  width: 100%;
  height: 595px;
  display: flex;
  max-width: 1200px;
  margin-top: 80px;
  align-items: center;
  flex-direction: column;
}
.contact-container1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.contact-text04 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.contact-text07 {
  line-height: 24px;
}
.contact-publish {
  height: initial;
}
.contact-text18 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.contact-text19 {
  line-height: 24px;
}
.contact-container2 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-sixunits);
  flex-direction: row;
}
.contact-text29 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: 16px;
}
.contact-text30 {
  line-height: 24px;
}
.contact-text40 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.contact-text41 {
  line-height: 24px;
}
.contact-footer {
  width: 100%;
  height: 80px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.contact-image {
  width: 226px;
  height: 2rem;
}
.contact-nav {
  display: flex;
}
.contact-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.contact-register {
  text-decoration: none;
}
.contact-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.contact-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contact-container3 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.contact-image1 {
  height: 2rem;
}
.contact-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.contact-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.contact-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.contact-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
@media(max-width: 991px) {
  .contact-hero {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .contact-close-mobile-menu {
    align-items: center;
    justify-content: center;
  }
  .contact-desktop-navigation {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .contact-centered {
    height: 60px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .contact-left {
    margin-left: var(--dl-space-space-halfunit);
  }
  .contact-logo1 {
    width: 311px;
  }
  .contact-links1 {
    display: none;
  }
  .contact-get-started {
    display: none;
  }
  .contact-mobile-menu-button {
    display: flex;
  }
  .contact-cards {
    margin-top: var(--dl-space-space-fiveunits);
  }
  .contact-text07 {
    line-height: 21px;
  }
  .contact-text19 {
    line-height: 21px;
  }
  .contact-text30 {
    line-height: 21px;
  }
  .contact-text40 {
    font-size: 24px;
  }
  .contact-text41 {
    line-height: 21px;
  }
}
@media(max-width: 767px) {
  .contact-hero {
    height: 620px;
    position: relative;
    padding-bottom: 180px;
    background-color: var(--dl-color-template-blue-bg);
  }
  .contact-desktop-navigation {
    padding-left: 0px;
    padding-right: 0px;
  }
  .contact-centered {
    padding-left: 0px;
    padding-right: 0px;
  }
  .contact-left {
    margin-left: var(--dl-space-space-unit);
  }
  .contact-right {
    margin-right: var(--dl-space-space-unit);
  }
  .contact-get-started {
    display: none;
  }
  .contact-container1 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .contact-text04 {
    font-size: 24px;
  }
  .contact-text07 {
    font-size: 14px;
  }
  .contact-publish {
    margin-left: 0px;
  }
  .contact-text18 {
    font-size: 24px;
  }
  .contact-text19 {
    font-size: 14px;
  }
  .contact-container2 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .contact-analyze {
    margin-right: 0px;
  }
  .contact-text29 {
    font-size: 24px;
  }
  .contact-text30 {
    font-size: 14px;
  }
  .contact-text41 {
    font-size: 14px;
  }
  .contact-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .contact-nav {
    display: none;
  }
  .contact-btn-group {
    display: none;
  }
}
@media(max-width: 479px) {
  .contact-hero {
    height: 1296px;
    padding-bottom: 160px;
  }
  .contact-logo {
    margin-left: 0px;
  }
  .contact-icon {
    align-items: center;
    justify-content: center;
  }
  .contact-cards {
    margin-top: var(--dl-space-space-threeunits);
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .contact-container1 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .contact-container2 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: 0px;
  }
  .contact-analyze {
    margin-bottom: 0px;
  }
  .contact-footer {
    padding: var(--dl-space-space-unit);
  }
  .contact-image {
    width: 311px;
    height: 50px;
  }
  .contact-mobile-menu {
    padding: 16px;
  }
}
