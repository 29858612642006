.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  flex-direction: column;
}
.home-hero {
  width: 100%;
  height: 1000px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #5228F5;
}
.home-menu {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-mobile-navigation {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 150;
  position: fixed;
  transform: translateX(100%);
  transition: transform 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-logo {
  width: 311px;
}
.home-links {
  gap: var(--dl-space-space-twounits);
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
}
.home-close-mobile-menu {
  top: var(--dl-space-space-oneandhalfunits);
  right: var(--dl-space-space-oneandhalfunits);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-icon {
  width: 24px;
  height: 24px;
  display: flex;
}
.home-desktop-navigation {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
}
.home-centered {
  width: 100%;
  height: 80px;
  display: flex;
  max-width: 1200px;
  box-sizing: initial;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: space-between;
  background-color: #ffffff;
}
.home-left {
  height: 55px;
  display: flex;
  align-items: center;
  padding-top: 38px;
  flex-direction: row;
  padding-bottom: 38px;
}
.home-navlink {
  display: contents;
}
.home-logo1 {
  width: 381px;
  height: 54px;
  margin-left: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.home-links1 {
  display: flex;
  align-items: center;
  margin-left: 33px;
  flex-direction: row;
}
.home-navlink1 {
  text-decoration: none;
}
.home-navlink2 {
  text-decoration: none;
}
.home-navlink3 {
  text-decoration: none;
}
.home-right {
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-get-started {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-color: #000000;
}
.home-get-started:hover {
  background-color: #5228F5;
}
.home-text03 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 600;
}
.home-burger-menu {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-mobile-menu-button {
  width: 40px;
  height: 40px;
  display: none;
}
.home-header {
  display: flex;
  margin-top: 140px;
  align-items: center;
  flex-direction: column;
}
.home-text04 {
  color: rgb(255, 255, 255);
  font-size: 62px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  line-height: 80px;
}
.home-text05 {
  color: rgb(238, 233, 254);
  width: 1000px;
  font-size: 18px;
  max-width: 1000px;
  margin-top: 16px;
  text-align: center;
  line-height: 30px;
}
.home-dashboard-preview {
  top: 650px;
  display: flex;
  position: absolute;
  align-items: center;
  border-color: #633df6;
  border-width: 30px;
  border-radius: 100px;
  flex-direction: column;
  background-color: #633df6;
}
.home-outline {
  display: flex;
  align-items: center;
  border-color: #7350f7;
  border-width: 20px;
  border-radius: 70px;
  flex-direction: column;
  background-color: #7350f7;
}
.home-image {
  width: 900px;
  z-index: 10;
  user-select: none;
}
.home-features {
  width: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  padding-top: 400px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: EFF0F2;
}
.home-title {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text09 {
  font-size: 56px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  line-height: 72px;
}
.home-text13 {
  font-size: 20px;
  margin-top: 16px;
  text-align: center;
  line-height: 30px;
}
.home-cards {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  max-width: 1200px;
  margin-top: 80px;
  align-items: center;
  flex-direction: column;
}
.home-statistics {
  width: 100%;
  height: 264px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  justify-content: center;
  background-color: #5228F5;
}
.home-container1 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-active-users {
  position: relative;
}
.home-text16 {
  color: rgb(255, 255, 255);
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
}
.home-text17 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  margin-top: 13px;
}
.home-active-users1 {
  position: relative;
}
.home-text20 {
  color: rgb(255, 255, 255);
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
}
.home-text23 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  margin-top: 13px;
}
.home-active-users2 {
  position: relative;
}
.home-text26 {
  color: rgb(255, 255, 255);
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
}
.home-text29 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  margin-top: 13px;
}
.home-active-users3 {
  position: relative;
}
.home-text32 {
  color: rgb(255, 255, 255);
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
}
.home-text35 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  margin-top: 13px;
}
.home-banners {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-banner-manage {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.home-container2 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-left1 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-text39 {
  text-align: left;
}
.home-text40 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-get-started1 {
  transition: 0.3s;
  margin-bottom: 0;
}
.home-get-started1:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-blue-bg);
}
.home-text41 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.home-image-container {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-cards-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  user-select: none;
}
.home-banner-advanced-analytics {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  background-color: #EFF0F2;
}
.home-centered-container {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  justify-content: center;
}
.home-image-container1 {
  flex: 1;
  display: flex;
}
.home-cards-image1 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.home-right1 {
  flex: 1;
  display: flex;
  margin-left: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-text43 {
  text-align: left;
}
.home-category {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
}
.home-text44 {
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text45 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text46 {
  color: #5228F5;
  cursor: pointer;
  font-style: normal;
  transition: 0.3s;
  font-weight: 600;
}
.home-text46:hover {
  color: #000000;
}
.home-category1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text47 {
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text48 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text49 {
  color: #5228F5;
  cursor: pointer;
  font-style: normal;
  transition: 0.3s;
  font-weight: 600;
}
.home-text49:hover {
  color: #000000;
}
.home-banner {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.home-container3 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-left2 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-text50 {
  color: rgb(82, 40, 245);
  font-style: normal;
  font-weight: 600;
  margin-bottom: 24px;
}
.home-text51 {
  text-align: left;
}
.home-text52 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-image-container2 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-cards-image2 {
  width: 370px;
  height: 100%;
  object-fit: contain;
}
.home-how-it-works {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  background-color: #211062;
}
.home-centered-container1 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
}
.home-heading {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-text53 {
  color: rgb(220, 212, 253);
  font-style: normal;
  font-weight: 600;
  margin-bottom: 24px;
}
.home-text54 {
  color: var(--dl-color-gray-white);
}
.home-category2 {
  color: white;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-self: center;
  padding-left: var(--dl-space-space-fourunits);
  border-radius: 50px;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: row;
  justify-content: center;
  background-color: #5228F5;
}
.home-container4 {
  flex: 1;
  height: 100%;
  display: flex;
  align-self: flex-end;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-pasted-image {
  width: 581px;
  align-self: stretch;
  object-fit: contain;
}
.home-row {
  display: flex;
  grid-gap: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: row;
}
.home-category3 {
  flex: 1;
  color: white;
  height: 346px;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: 50px;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
}
.home-pasted-image1 {
  width: 85%;
  align-self: center;
}
.home-category4 {
  flex: 1;
  color: white;
  height: 350px;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: 50px;
  flex-direction: column;
  justify-content: space-between;
  background-color: #000000;
}
.home-pasted-image2 {
  height: 105%;
  align-self: center;
  object-fit: contain;
}
.home-testimonals {
  width: 100%;
  display: flex;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.home-left3 {
  width: 375px;
  display: flex;
  margin-top: 375px;
  align-items: flex-start;
  margin-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-text55 {
  color: rgb(82, 40, 245);
  font-style: normal;
  font-weight: 600;
  margin-bottom: 24px;
}
.home-text56 {
  text-align: left;
}
.home-right2 {
  gap: 40px;
  display: flex;
  flex-direction: row;
}
.home-column {
  gap: 40px;
  display: flex;
  flex-direction: column;
}
.home-column1 {
  gap: 40px;
  display: flex;
  flex-direction: column;
}
.home-text57 {
  cursor: pointer;
  display: none;
}
.home-action-bar {
  width: 100%;
  display: flex;
  padding: 120px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #EFF0F2;
}
.home-action {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-end;
  border-radius: 50px;
  flex-direction: row;
  justify-content: space-between;
  background-color: #5228F5;
}
.home-heading1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sevenunits);
  padding-left: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sevenunits);
}
.home-text60 {
  color: var(--dl-color-gray-white);
  align-self: center;
  text-align: left;
}
.home-get-started2 {
  margin-bottom: 0px;
}
.home-text63 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
}
.home-images {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}
.home-footer {
  width: 100%;
  height: 80px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.home-image1 {
  width: 226px;
  height: 2rem;
}
.home-nav {
  display: flex;
}
.home-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-register {
  text-decoration: none;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.home-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container5 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-image2 {
  height: 2rem;
}
.home-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
@media(max-width: 991px) {
  .home-hero {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-close-mobile-menu {
    align-items: center;
    justify-content: center;
  }
  .home-desktop-navigation {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-centered {
    height: 60px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .home-left {
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-logo1 {
    width: 296px;
  }
  .home-links1 {
    display: none;
  }
  .home-get-started {
    display: none;
  }
  .home-mobile-menu-button {
    display: flex;
  }
  .home-header {
    width: 100%;
    max-width: 1200px;
  }
  .home-image {
    width: 100%;
  }
  .home-features {
    padding-top: 260px;
  }
  .home-title {
    width: 100%;
    max-width: 1200px;
  }
  .home-text09 {
    font-size: 36px;
    line-height: 40px;
  }
  .home-text13 {
    font-size: 20px;
    margin-top: var(--dl-space-space-unit);
    line-height: 30px;
  }
  .home-cards {
    margin-top: var(--dl-space-space-fiveunits);
  }
  .home-statistics {
    height: 800px;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-container1 {
    height: 100%;
    flex-direction: column;
  }
  .home-banner-manage {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-left1 {
    margin-right: var(--dl-space-space-threeunits);
  }
  .home-cards-image {
    width: 360px;
  }
  .home-banner-advanced-analytics {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-category2 {
    padding-left: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-container4 {
    width: 100%;
  }
  .home-pasted-image {
    height: auto;
  }
  .home-testimonals {
    align-items: center;
    flex-direction: column;
  }
  .home-left3 {
    margin-top: 0px;
    align-items: center;
    margin-bottom: ThreeUnits;
  }
  .home-text56 {
    font-size: 36px;
    text-align: center;
    line-height: 40px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-right2 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
  }
  .home-column {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-column1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-action {
    flex-direction: column;
  }
  .home-heading1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-text60 {
    font-size: 36px;
    line-height: 36px;
  }
}
@media(max-width: 767px) {
  .home-hero {
    height: 620px;
    position: relative;
    padding-bottom: 180px;
    background-color: var(--dl-color-template-blue-bg);
  }
  .home-desktop-navigation {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-centered {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-left {
    margin-left: var(--dl-space-space-unit);
  }
  .home-right {
    margin-right: var(--dl-space-space-unit);
  }
  .home-get-started {
    display: none;
  }
  .home-header {
    height: 100%;
    margin-top: var(--dl-space-space-fourunits);
    justify-content: center;
  }
  .home-text04 {
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
  }
  .home-text05 {
    color: rgb(238, 233, 254);
    font-size: 16px;
    margin-top: var(--dl-space-space-unit);
    line-height: 24px;
  }
  .home-dashboard-preview {
    top: 520px;
    width: 90%;
    border-width: 10px;
    border-radius: 45px;
  }
  .home-outline {
    border-width: 10px;
    border-radius: 35px;
  }
  .home-image {
    width: 100%;
  }
  .home-features {
    padding-top: 350px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-text09 {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }
  .home-statistics {
    height: 100%;
  }
  .home-container1 {
    gap: var(--dl-space-space-fourunits);
    justify-content: center;
  }
  .home-text16 {
    font-size: 28px;
  }
  .home-text17 {
    font-size: 14px;
  }
  .home-text20 {
    font-size: 28px;
  }
  .home-text23 {
    font-size: 14px;
  }
  .home-text26 {
    font-size: 28px;
  }
  .home-text29 {
    font-size: 14px;
  }
  .home-text32 {
    font-size: 28px;
  }
  .home-text35 {
    font-size: 14px;
  }
  .home-banner-manage {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-container2 {
    flex-direction: column;
  }
  .home-left1 {
    margin-right: 0px;
  }
  .home-text39 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text40 {
    line-height: 24px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text41 {
    font-size: 16px;
  }
  .home-image-container {
    justify-content: center;
  }
  .home-cards-image {
    margin-top: var(--dl-space-space-threeunits);
  }
  .home-banner-advanced-analytics {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-centered-container {
    flex-direction: column-reverse;
  }
  .home-right1 {
    margin-left: 0px;
  }
  .home-text43 {
    font-size: 28px;
    text-align: left;
    line-height: 26px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text44 {
    font-size: 20px;
  }
  .home-text45 {
    line-height: 24px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text47 {
    font-size: 20px;
  }
  .home-text48 {
    line-height: 24px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-banner {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-container3 {
    flex-direction: column;
  }
  .home-left2 {
    margin-right: 0px;
  }
  .home-how-it-works {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-heading {
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-text54 {
    margin-bottom: 0px;
  }
  .home-container4 {
    width: 100%;
  }
  .home-row {
    height: auto;
    flex-direction: column;
  }
  .home-testimonals {
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
    padding-bottom: 0px;
  }
  .home-right2 {
    width: 100%;
    flex-direction: column;
  }
  .home-column1 {
    display: none;
  }
  .home-text57 {
    display: flex;
    font-style: normal;
    margin-top: var(--dl-space-space-oneandhalfunits);
    font-weight: 600;
  }
  .home-action-bar {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-action {
    align-items: center;
  }
  .home-heading1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-text60 {
    font-size: 28px;
    text-align: left;
  }
  .home-text63 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
  .home-images {
    justify-content: center;
  }
  .home-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-nav {
    display: none;
  }
  .home-btn-group {
    display: none;
  }
}
@media(max-width: 479px) {
  .home-hero {
    padding-bottom: 160px;
  }
  .home-logo {
    margin-left: 0px;
  }
  .home-icon {
    align-items: center;
    justify-content: center;
  }
  .home-logo1 {
    width: 290px;
  }
  .home-text04 {
    color: rgb(255, 255, 255);
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    line-height: 80px;
  }
  .home-text05 {
    color: #EEE9FE;
    width: 479px;
  }
  .home-dashboard-preview {
    top: 500px;
  }
  .home-features {
    padding-top: 200px;
  }
  .home-cards {
    margin-top: var(--dl-space-space-threeunits);
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-statistics {
    height: 500px;
  }
  .home-text16 {
    font-size: 28px;
  }
  .home-text17 {
    color: rgb(255, 255, 255);
    font-size: 14px;
  }
  .home-text20 {
    font-size: 28px;
  }
  .home-text23 {
    color: rgb(255, 255, 255);
    font-size: 14px;
  }
  .home-text26 {
    font-size: 28px;
  }
  .home-text29 {
    color: rgb(255, 255, 255);
    font-size: 14px;
  }
  .home-text32 {
    font-size: 28px;
  }
  .home-text35 {
    color: rgb(255, 255, 255);
    font-size: 14px;
  }
  .home-text40 {
    line-height: 24px;
  }
  .home-cards-image {
    width: 100%;
    margin-top: var(--dl-space-space-twounits);
  }
  .home-category2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .home-pasted-image {
    width: 479px;
    height: 401px;
  }
  .home-pasted-image1 {
    width: 479px;
    height: 100%;
    align-self: center;
  }
  .home-pasted-image2 {
    align-self: center;
  }
  .home-testimonals {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-action-bar {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-footer {
    padding: var(--dl-space-space-unit);
  }
  .home-image1 {
    width: 311px;
    height: 50px;
  }
  .home-mobile-menu {
    padding: 16px;
  }
}
