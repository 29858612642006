.about-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.about-hero {
  width: 100%;
  height: 1300px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #5228F5;
}
.about-menu {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.about-mobile-navigation {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 150;
  position: fixed;
  transform: translateX(100%);
  transition: transform 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.about-logo {
  width: 311px;
}
.about-links {
  gap: var(--dl-space-space-twounits);
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
}
.about-close-mobile-menu {
  top: var(--dl-space-space-oneandhalfunits);
  right: var(--dl-space-space-oneandhalfunits);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.about-icon {
  width: 24px;
  height: 24px;
  display: flex;
}
.about-desktop-navigation {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
}
.about-centered {
  width: 100%;
  height: 80px;
  display: flex;
  max-width: 1200px;
  box-sizing: initial;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: space-between;
  background-color: #ffffff;
}
.about-left {
  height: 55px;
  display: flex;
  align-items: center;
  padding-top: 38px;
  flex-direction: row;
  padding-bottom: 38px;
}
.about-navlink {
  display: contents;
}
.about-logo1 {
  width: 381px;
  height: 54px;
  margin-left: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.about-links1 {
  display: flex;
  align-items: center;
  margin-left: 33px;
  flex-direction: row;
}
.about-navlink1 {
  text-decoration: none;
}
.about-navlink2 {
  text-decoration: none;
}
.about-navlink3 {
  text-decoration: none;
}
.about-right {
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.about-get-started {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-color: #000000;
}
.about-get-started:hover {
  background-color: #5228F5;
}
.about-text03 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 600;
}
.about-burger-menu {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.about-mobile-menu-button {
  width: 40px;
  height: 40px;
  display: none;
}
.about-header {
  height: 824px;
  display: flex;
  margin-top: 140px;
  align-items: center;
  flex-direction: column;
}
.about-text04 {
  color: rgb(255, 255, 255);
  font-size: 72px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  line-height: 80px;
}
.about-text05 {
  color: rgb(238, 233, 254);
  width: 800px;
  font-size: 20px;
  max-width: 800px;
  margin-top: 16px;
  text-align: center;
  line-height: 30px;
}
.about-text09 {
  font-style: normal;
  font-weight: 700;
}
.about-text14 {
  font-style: normal;
  font-weight: 700;
}
.about-text23 {
  font-style: normal;
  font-weight: 700;
}
.about-text30 {
  font-style: normal;
  font-weight: 700;
}
.about-footer {
  width: 100%;
  height: 80px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.about-image {
  width: 226px;
  height: 2rem;
}
.about-nav {
  display: flex;
}
.about-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.about-register {
  text-decoration: none;
}
.about-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.about-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.about-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.about-image1 {
  height: 2rem;
}
.about-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.about-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.about-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.about-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
@media(max-width: 991px) {
  .about-hero {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .about-close-mobile-menu {
    align-items: center;
    justify-content: center;
  }
  .about-desktop-navigation {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .about-centered {
    height: 60px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .about-left {
    margin-left: var(--dl-space-space-halfunit);
  }
  .about-logo1 {
    width: 311px;
  }
  .about-links1 {
    display: none;
  }
  .about-get-started {
    display: none;
  }
  .about-mobile-menu-button {
    display: flex;
  }
  .about-header {
    width: 100%;
    max-width: 1200px;
  }
}
@media(max-width: 767px) {
  .about-hero {
    height: 620px;
    position: relative;
    padding-bottom: 180px;
    background-color: var(--dl-color-template-blue-bg);
  }
  .about-desktop-navigation {
    padding-left: 0px;
    padding-right: 0px;
  }
  .about-centered {
    padding-left: 0px;
    padding-right: 0px;
  }
  .about-left {
    margin-left: var(--dl-space-space-unit);
  }
  .about-right {
    margin-right: var(--dl-space-space-unit);
  }
  .about-get-started {
    display: none;
  }
  .about-header {
    height: 100%;
    margin-top: var(--dl-space-space-fourunits);
    justify-content: center;
  }
  .about-text04 {
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
  }
  .about-text05 {
    color: rgb(238, 233, 254);
    font-size: 16px;
    margin-top: var(--dl-space-space-unit);
    line-height: 24px;
  }
  .about-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .about-nav {
    display: none;
  }
  .about-btn-group {
    display: none;
  }
}
@media(max-width: 479px) {
  .about-hero {
    height: 1136px;
    padding-bottom: 160px;
  }
  .about-logo {
    margin-left: 0px;
  }
  .about-icon {
    align-items: center;
    justify-content: center;
  }
  .about-logo1 {
    width: 310px;
  }
  .about-header {
    height: 559px;
  }
  .about-text05 {
    color: rgb(238, 233, 254);
    width: 479px;
    height: 474px;
    font-size: 16px;
    line-height: 30px;
  }
  .about-text09 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }
  .about-text10 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }
  .about-text14 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }
  .about-text23 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }
  .about-text30 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }
  .about-text31 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }
  .about-footer {
    padding: var(--dl-space-space-unit);
  }
  .about-image {
    width: 311px;
    height: 50px;
  }
  .about-mobile-menu {
    padding: 16px;
  }
}
