.c-d-v-c-i-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.c-d-v-c-i-hero {
  width: 100%;
  height: 720px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #5228F5;
}
.c-d-v-c-i-menu {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.c-d-v-c-i-mobile-navigation {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 150;
  position: fixed;
  transform: translateX(100%);
  transition: transform 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.c-d-v-c-i-logo {
  width: 311px;
}
.c-d-v-c-i-links {
  gap: var(--dl-space-space-twounits);
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
}
.c-d-v-c-i-close-mobile-menu {
  top: var(--dl-space-space-oneandhalfunits);
  right: var(--dl-space-space-oneandhalfunits);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.c-d-v-c-i-icon {
  width: 24px;
  height: 24px;
  display: flex;
}
.c-d-v-c-i-desktop-navigation {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
}
.Dropdown-control {
    position: relative;
    overflow: hidden;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-sizing: border-box;
    color: #333;
    cursor: default;
    outline: none;
    padding: 8px 52px 8px 10px;
    transition: all 200ms ease;
    width: 600px;
}
.c-d-v-c-i-centered {
  width: 100%;
  height: 80px;
  display: flex;
  max-width: 1200px;
  box-sizing: initial;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: space-between;
  background-color: #ffffff;
}
.c-d-v-c-i-left {
  height: 55px;
  display: flex;
  align-items: center;
  padding-top: 38px;
  flex-direction: row;
  padding-bottom: 38px;
}
.c-d-v-c-i-navlink {
  display: contents;
}
.c-d-v-c-i-logo1 {
  width: 381px;
  height: 54px;
  margin-left: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.c-d-v-c-i-links1 {
  display: flex;
  align-items: center;
  margin-left: 33px;
  flex-direction: row;
}
.c-d-v-c-i-navlink1 {
  text-decoration: none;
}
.c-d-v-c-i-navlink2 {
  text-decoration: none;
}
.c-d-v-c-i-navlink3 {
  text-decoration: none;
}
.c-d-v-c-i-right {
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.c-d-v-c-i-get-started {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-color: #000000;
}
.c-d-v-c-i-get-started:hover {
  background-color: #5228F5;
}
.c-d-v-c-i-text03 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 600;
}
.c-d-v-c-i-burger-menu {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.c-d-v-c-i-mobile-menu-button {
  width: 40px;
  height: 40px;
  display: none;
}
.c-d-v-c-i-header {
  display: flex;
  margin-top: 140px;
  align-items: center;
  flex-direction: column;
}
.c-d-v-c-i-text04 {
  color: rgb(255, 255, 255);
  font-size: 72px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  line-height: 80px;
}
.c-d-v-c-i-text05 {
  color: rgb(238, 233, 254);
  width: 653px;
  font-size: 16px;
  max-width: 600px;
  margin-top: 16px;
  text-align: center;
  line-height: 30px;
}
.c-d-v-c-i-banner-advanced-analytics {
  width: 100%;
  /* height: 50vh; */
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-self: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 80px;
  justify-content: center;
  background-color: #EFF0F2;
}
.c-d-v-c-i-centered-container {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  justify-content: center;
}
.c-d-v-c-i-right1 {
  flex: 1;
  display: flex;
  margin-left: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.c-d-v-c-i-category {
  display: flex;
  position: relative;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
}
.c-d-v-c-i-text09 {
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.c-d-v-c-i-select {
  width: auto;
  height: var(--dl-size-size-small);
  align-self: stretch;
  border-color: #000000;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
}
.c-d-v-c-i-category1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.c-d-v-c-i-text10 {
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.c-d-v-c-i-select1 {
  width: auto;
  height: var(--dl-size-size-small);
  align-self: stretch;
  border-color: #000000;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
}
.c-d-v-c-i-footer {
  width: 100%;
  height: 80px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.c-d-v-c-i-image {
  width: 226px;
  height: 2rem;
}
.c-d-v-c-i-nav {
  display: flex;
}
.c-d-v-c-i-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.c-d-v-c-i-register {
  text-decoration: none;
}
.c-d-v-c-i-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.c-d-v-c-i-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.c-d-v-c-i-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.c-d-v-c-i-image1 {
  height: 2rem;
}
.c-d-v-c-i-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-d-v-c-i-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.c-d-v-c-i-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.c-d-v-c-i-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.c-d-v-c-i-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.tableContainer{
  
  border-radius: 20px;
  height: 60vh;
  padding: 0 10px 10px 10px;
  overflow: scroll;
  /* width: 10vw; */
  text-align: center;
  align-items: center;
}
Table{
  width: 80vw;
}
Table thead{
  position: sticky;
  top: 0px;
  margin: 0 0 0 0;
  width: 100%;
}
thead th {
  background-color: #5228f5;
  color: white;
  font-weight: bold;
  font-size: 20px;
  padding: 10px;
  width:25%;
  
}
@media(max-width: 991px) {
  .c-d-v-c-i-hero {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .c-d-v-c-i-close-mobile-menu {
    align-items: center;
    justify-content: center;
  }
  .c-d-v-c-i-desktop-navigation {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .c-d-v-c-i-centered {
    height: 60px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .c-d-v-c-i-left {
    margin-left: var(--dl-space-space-halfunit);
  }
  .c-d-v-c-i-logo1 {
    width: 311px;
  }
  .c-d-v-c-i-links1 {
    display: none;
  }
  .c-d-v-c-i-get-started {
    display: none;
  }
  .c-d-v-c-i-mobile-menu-button {
    display: flex;
  }
  .c-d-v-c-i-header {
    width: 100%;
    max-width: 1200px;
  }
  .c-d-v-c-i-banner-advanced-analytics {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
}
@media(max-width: 767px) {
  .c-d-v-c-i-hero {
    height: 620px;
    position: relative;
    padding-bottom: 180px;
    background-color: var(--dl-color-template-blue-bg);
  }
  .c-d-v-c-i-desktop-navigation {
    padding-left: 0px;
    padding-right: 0px;
  }
  .c-d-v-c-i-centered {
    padding-left: 0px;
    padding-right: 0px;
  }
  .c-d-v-c-i-left {
    margin-left: var(--dl-space-space-unit);
  }
  .c-d-v-c-i-right {
    margin-right: var(--dl-space-space-unit);
  }
  .c-d-v-c-i-get-started {
    display: none;
  }
  .c-d-v-c-i-header {
    height: 100%;
    margin-top: var(--dl-space-space-fourunits);
    justify-content: center;
  }
  .c-d-v-c-i-text04 {
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
  }
  .c-d-v-c-i-text05 {
    color: rgb(238, 233, 254);
    font-size: 16px;
    margin-top: var(--dl-space-space-unit);
    line-height: 24px;
  }
  .c-d-v-c-i-banner-advanced-analytics {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .c-d-v-c-i-centered-container {
    flex-direction: column-reverse;
  }
  .c-d-v-c-i-right1 {
    margin-left: 0px;
  }
  .c-d-v-c-i-text09 {
    font-size: 20px;
  }
  .c-d-v-c-i-text10 {
    font-size: 20px;
  }
  .c-d-v-c-i-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .c-d-v-c-i-nav {
    display: none;
  }
  .c-d-v-c-i-btn-group {
    display: none;
  }
}

@media(max-width: 479px) {
  .c-d-v-c-i-hero {
    height: 419px;
    padding-bottom: 160px;
  }
  .c-d-v-c-i-logo {
    margin-left: 0px;
  }
  .c-d-v-c-i-icon {
    align-items: center;
    justify-content: center;
  }
  .c-d-v-c-i-logo1 {
    width: 310px;
  }
  .c-d-v-c-i-text05 {
    color: #EEE9FE;
    width: 479px;
    height: 73px;
  }
  .c-d-v-c-i-banner-advanced-analytics {
    height: 500px;
  }
  .c-d-v-c-i-select {
    width: 412px;
  }
  .c-d-v-c-i-footer {
    padding: var(--dl-space-space-unit);
  }
  .c-d-v-c-i-image {
    width: 311px;
    height: 50px;
  }
  .c-d-v-c-i-mobile-menu {
    padding: 16px;
  }
}
